"use strict";

function paddingtop() {
    if($('#home').length > 0){
        var hh = $(window).height() - $('.navbar').outerHeight();
        $('#home').css('height',$(window).height()+"px");
    }

}

paddingtop();
$(document).ready(function () {
    $(window).scroll(function() {
        if ($(document).scrollTop() > 50) {
            $("nav.navbar").addClass("scrolled");
        } else {
            $("nav.navbar").removeClass("scrolled");
        }
    });

    $('a[href^="#"]').on('click', function (event) {
        $('navbar').dropdown('toggle');
        var target = $($(this).attr('href'));

        if (target.length) {
            event.preventDefault();
            $('.navbar-toggler').addClass('collapsed');
            $('.navbar-collapse').removeClass('show');
            $('html, body').animate({
                scrollTop: target.offset().top - ( $('.navbar').outerHeight())
            }, 750,function () {

            });
        }

    });

    $('#contactform').on("submit", function (e) {
        //e.preventDefault();
    })
    $(".home-slide").owlCarousel({
        loop:true,
        nav:false,
        items:1,
        autoplay:true,
        animateOut: 'fadeOut',
        dots:false,
    });
    $(".referances-slide").owlCarousel({
        loop:true,
        nav:false,
        items:1,
        autoplay:true,
        dots:false,
    });
    $(".suppliers-slide").owlCarousel({
        loop:true,
        nav:false,
        items:1,
        autoplay:true,
        dots:false,
    });

});
$(window).resize(function () {
    paddingtop();
})